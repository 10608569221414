
import { useState, useContext } from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import { AuthContext } from '../contexts/auth';

import Site from '../pages/Site';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

import Dashboard from '../pages/Dashboard';
import Config from '../pages/Config';
import Configlista from '../pages/Configlistar';
import Profile from '../pages/Profile';
import Textos from '../pages/Textos';
import Textoslista from '../pages/Textoslistar';
import Cidades from '../pages/Cidades';
import Cidadeslista from '../pages/Cidadeslistar';
import Seção from '../pages/Secao';
import Planos from '../pages/Planos';
import Planoslista from '../pages/Planoslistar';
import Sistema from '../pages/Sistema';
import Sistemalista from '../pages/Sistemalistar';
import Pontosdeinteresse from '../pages/Pontosinteresse';
import Pontosdeinteresselista from '../pages/Pontosinteresselistar';


export default function Routes(){
  const { user } = useContext(AuthContext);
  const [tipo, setTipo] = useState(user && user.tipo);
  if (tipo==='adm'){
    return(
        <Switch>
        <Route exact path="/" component={Site} />
        <Route exact path="/login" component={SignIn} />
        <Route exact path="/register" component={SignUp} />
        <Route exact path="/dashboard" component={Dashboard} isPrivate />
        <Route exact path="/profile" component={Profile} isPrivate />

        <Route exact path="/config" component={Config} isPrivate />
        <Route exact path="/config/:id" component={Config} isPrivate />
        <Route exact path="/configlistar" component={Configlista} isPrivate />
        <Route exact path="/textos" component={Textos} isPrivate />
        <Route exact path="/textos/:id" component={Textos} isPrivate />
        <Route exact path="/textoslistar" component={Textoslista} isPrivate />
        <Route exact path="/sistema" component={Sistema} isPrivate />
        <Route exact path="/sistema/:id" component={Sistema} isPrivate />
        <Route exact path="/sistemalistar" component={Sistemalista} isPrivate />
        <Route exact path="/cidades" component={Cidades} isPrivate />
        <Route exact path="/cidades/:id" component={Cidades} isPrivate />
        <Route exact path="/cidadeslistar" component={Cidadeslista} isPrivate />
        <Route exact path="/secao" component={Seção} isPrivate />
        <Route exact path="/planos" component={Planos} isPrivate />
        <Route exact path="/planos/:id" component={Planos} isPrivate />
        <Route exact path="/planoslistar" component={Planoslista} isPrivate />
        <Route exact path="/pontosdeinteresse" component={Pontosdeinteresse} isPrivate />
        <Route exact path="/pontosdeinteresse/:id" component={Pontosdeinteresse} isPrivate />
        <Route exact path="/pontosdeinteresselistar" component={Pontosdeinteresselista} isPrivate />
        </Switch>
  )
}
else {
  return(
      <Switch>
        <Route exact path="/" component={Site} />
        <Route exact path="/login" component={SignIn} />
        <Route exact path="/register" component={SignUp} />
        <Route exact path="/dashboard" component={Dashboard} isPrivate />
        <Route exact path="/profile" component={Profile} isPrivate />

        <Route exact path="/config" component={Config} isPrivate />
        <Route exact path="/config/:id" component={Config} isPrivate />
        <Route exact path="/configlistar" component={Configlista} isPrivate />
        <Route exact path="/textos" component={Textos} isPrivate />
        <Route exact path="/textos/:id" component={Textos} isPrivate />
        <Route exact path="/textoslistar" component={Textoslista} isPrivate />
        <Route exact path="/sistema" component={Sistema} isPrivate />
        <Route exact path="/sistema/:id" component={Sistema} isPrivate />
        <Route exact path="/sistemalistar" component={Sistemalista} isPrivate />
        <Route exact path="/cidades" component={Cidades} isPrivate />
        <Route exact path="/cidades/:id" component={Cidades} isPrivate />
        <Route exact path="/cidadeslistar" component={Cidadeslista} isPrivate />
        <Route exact path="/secao" component={Seção} isPrivate />
        <Route exact path="/planos" component={Planos} isPrivate />
        <Route exact path="/planos/:id" component={Planos} isPrivate />
        <Route exact path="/planoslistar" component={Planoslista} isPrivate />
        <Route exact path="/pontosdeinteresse" component={Pontosdeinteresse} isPrivate />
        <Route exact path="/pontosdeinteresse/:id" component={Pontosdeinteresse} isPrivate />
        <Route exact path="/pontosdeinteresselistar" component={Pontosdeinteresselista} isPrivate />
        
      </Switch>
)
}

}