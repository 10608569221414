
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './cidades.css';
import { FiPlusCircle } from 'react-icons/fi'

export default function Textos(){
  const { id } = useParams();
  const history = useHistory();

  const [loadCidades, setLoadCidades] = useState(true);
  const [cidades, setCidades] = useState([]);
  const [cidadesSelected, setCidadesSelected] = useState(0);

  const [nomecidade, setNomecidade] = useState('');
  const [estadocidade, setEstadocidade] = useState('');
  const [textosimplescidade, setTextosimplescidade] = useState('');
  const [textocompletocidade, setTextocompletocidade] = useState('');
  const [linkfbcidade, setLinkfbcidade] = useState('');
  const [linkinstagramcidade, setLinkinstagramcidade] = useState('');
  const [linksitecidade, setLinksitecidade] = useState('');

  const [idCidades, setIdCidades] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(()=> {
    async function loadCidades(){
      await firebase.firestore().collection('cidades')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomecidade: doc.data().nomecidade
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA CIDADE ENCONTRADA');
          setCidades([ { id: '1', nomecidade: 'AVULSO' } ]);
          setLoadCidades(false);
          return;
        }

        setCidades(lista);
        setLoadCidades(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadCidades(false);
        setCidades([ { id: '1', nomecidade: '' } ]);
      })

    }
    loadCidades();
  }, [id]);


  async function loadId(lista){
    await firebase.firestore().collection('cidades').doc(id)
    .get()
    .then((snapshot) => {
      setNomecidade(snapshot.data().nomecidade);
      setEstadocidade(snapshot.data().estadocidade);
      setTextosimplescidade(snapshot.data().textosimplescidade);
      setTextocompletocidade(snapshot.data().textocompletocidade);
      setLinkfbcidade(snapshot.data().linkfbcidade);
      setLinkinstagramcidade(snapshot.data().linkinstagramcidade);
      setLinksitecidade(snapshot.data().linksitecidade)

      let index = lista.findIndex(item => item.id === snapshot.data().projetoId );
      setCidadesSelected(index);
      setIdCidades(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdCidades(false);
    })
  }

  async function handleRegister(e){
    e.preventDefault();

    if(idCidades){
      await firebase.firestore().collection('cidades')
      .doc(id)
      .update({
        nomecidade: nomecidade,
        estadocidade: estadocidade,
        textosimplescidade: textosimplescidade,
        textocompletocidade: textocompletocidade,
        linkfbcidade: linkfbcidade,
        linkinstagramcidade: linkinstagramcidade,
        linksitecidade: linksitecidade,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Cidade editada com sucesso!');
        setCidadesSelected(0);
        setNomecidade('');
        history.push('/cidadeslistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

      return;
    }

    await firebase.firestore().collection('cidades')
    .add({
      created: new Date(),
      nomecidade: nomecidade,
      estadocidade: estadocidade,
      textosimplescidade: textosimplescidade,
      textocompletocidade: textocompletocidade,
      linkfbcidade: linkfbcidade,
      linkinstagramcidade: linkinstagramcidade,
      linksitecidade: linksitecidade,
      userId: user.uid
    })
    .then(()=> {
      toast.success('Cidade cadastrada com sucesso!');
      setNomecidade('');
      setEstadocidade('');
      setTextosimplescidade('');
      setTextocompletocidade('');
      setLinkfbcidade('');
      setLinkinstagramcidade('');
      setLinksitecidade('');
      setCidadesSelected(0);
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })


  }


  //Chamado quando troca o estado
  function handleChangeEstado(e){
    setEstadocidade(e.target.value);
  }


  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Nova Cidade">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >
            <label>Cidade</label>
            <input type="text" placeholder="Nome da cidade" value={nomecidade} onChange={ (e) => setNomecidade(e.target.value) } />

            <label>Estado</label>
            <select value={estadocidade} onChange={handleChangeEstado}>
              <option value="MG">Mina Gerais</option>
              <option value="RJ">Rio de Janeiro</option>
            </select>


            <label>Texto Simples - 100 caracteres</label>
            <textarea
              type="text"
              placeholder="texto simples"
              value={textosimplescidade}
              onChange={ (e) => setTextosimplescidade(e.target.value) }
            />

            <label>Texto Completo - 500 caracteres</label>
            <textarea
              type="text"
              placeholder="texto completo"
              value={textocompletocidade}
              onChange={ (e) => setTextocompletocidade(e.target.value) }
            />

            <label>Facebook</label>
            <input type="text" placeholder="link Facebook" value={linkfbcidade} onChange={ (e) => setLinkfbcidade(e.target.value) } />
            <label>Instagram</label>
            <input type="text" placeholder="link Instagram" value={linkinstagramcidade} onChange={ (e) => setLinkinstagramcidade(e.target.value) } />
            <label>Página</label>
            <input type="text" placeholder="link sem o http://" value={linksitecidade} onChange={ (e) => setLinksitecidade(e.target.value) } />
            
            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}