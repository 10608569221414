import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

let firebaseConfig = {
  apiKey: "AIzaSyDRZitibHXC6cS92h5Harhzt7yjNykPW6o",
  authDomain: "prefeitura-digital-5efc7.firebaseapp.com",
  databaseURL: "https://prefeitura-digital-5efc7.firebaseio.com",
  projectId: "prefeitura-digital-5efc7",
  storageBucket: "prefeitura-digital-5efc7.appspot.com",
  messagingSenderId: "283312992639",
  appId: "1:283312992639:web:19fa7a51c54ff712dd6a4a",
  measurementId: "G-ZYX3X0N3EM"
};

if(!firebase.apps.length){
  firebase.initializeApp(firebaseConfig);
}

export default firebase;