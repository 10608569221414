
import { useContext, useState } from 'react';
import './header.css';
import { AuthContext } from '../../contexts/auth';
import avatar from '../../assets/avatar.png';

import { Link } from 'react-router-dom';
import { FiHome, FiUser, FiSettings, FiMapPin, FiSliders, FiType, FiCoffee, FiBook, FiGlobe, FiGrid} from "react-icons/fi";

export default function Header(){
  const { user, signOut } = useContext(AuthContext);
  const [tipo, setTipo] = useState(user && user.tipo);
  
  if (tipo==='adm'){
    return(
    <div className="sidebar">
      <div>
        <img src={user.avatarUrl === null ? avatar : user.avatarUrl } alt="Foto avatar" />
      </div>
      <Link to="/dashboard">
        <FiHome color="#FFF" size={24} />
        Início
      </Link>
      <Link to="/profile">
        <FiSettings color="#FFF" size={24} />
        Configurações Usuário
      </Link>
      <Link to="/configlistar">
        <FiSliders color="#FFF" size={24} />
        Configurações Página
      </Link>
      <Link to="/secao">
        <FiGrid color="#FFF" size={24} />
        Seção
      </Link>
      <Link to="/textoslistar">
        <FiType color="#FFF" size={24} />
        Textos
      </Link>         
      <Link to="/sistemalistar">
        <FiCoffee color="#FFF" size={24} />
        Sistema
      </Link>
      <Link to="/planoslistar">
        <FiBook color="#FFF" size={24} />
        Planos
      </Link>
      <Link to="/cidadeslistar">
        <FiGlobe color="#FFF" size={24} />
        Cidades
      </Link>
      <Link to="/projetoslistar">
        <FiBook color="#FFF" size={24} />
        Projetos
      </Link>
      <Link to="/pontosdeinteresselistar">
        <FiMapPin color="#FFF" size={24} />
        Pontos de Interesse
      </Link>
      <button className="logout-btn" onClick={ () => signOut() } >
               Sair
      </button>
    </div>
  )
}
else {
  return(
    <div className="sidebar">
      <div>
        <img src={user.avatarUrl === null ? avatar : user.avatarUrl } alt="Foto avatar" />
      </div>
      <Link to="/dashboard">
        <FiHome color="#FFF" size={24} />
        Início
      </Link>
      <Link to="/profile">
        <FiSettings color="#FFF" size={24} />
        Configurações Usuário
      </Link>
      <button className="logout-btn" onClick={ () => signOut() } >
               Sair
      </button>
    </div>
)
}

}